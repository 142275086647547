@import '../../theme';

.form {
  margin: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  min-width: 300px;

  background: $formBackgroundColor;
  padding: 20px;

  @media screen and (max-width: 400px) {
    width: 382px;
    margin: 10px auto;
    padding: 15px;
  }

  @media screen and (max-width: 390px) {
    width: auto;
    margin: 10px 4px;
  }

  .actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: -15px;

    .form-button {
      flex: 1 1 200px;
      min-width: 200px;
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 15px;
    }
  }

  &.small {
    max-width: 400px;
  }

  p {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    &:last-child {
      margin-bottom: 0;
    }
    &.right {
      text-align: right;
    }
  }

  h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1 {
    font-family: 'Roboto', sans-serif;
    &:first-child {
      margin-top: 0;
    }
  }
}
