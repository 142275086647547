.page {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;

  @media screen and (max-width: 800px) {
    padding: 20px 0;
  }

  &.loading {
    .page-wrapper {
      overflow: hidden;

      &:before {
        display: block;
        content: ' ';
        background-image: url('../../resources/images/loader.svg');
        background-size: 60px 60px;
        height: 60px;
        width: 60px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -30px;
        margin-left: -30px;
        z-index: 1000;
      }

      &:after {
        content: ' ';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.76);
      }
    }
  }

  .page-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 40px;

    .content-wrapper {
      max-width: 1200px;
      padding: 0 40px;

      @media screen and (max-width: 800px) {
        padding: 0;
        width: 680px;
        margin: 0 auto;
      }

      @media screen and (max-width: 680px) {
        padding: 0;
        width: auto;
        margin: 0 auto;
      }

      .form-content {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
      }

      .form-data {
        margin: 16px 0 0;
        width: 100%;

        & > * {
          margin: 0 auto;
        }

        @media screen and (max-width: 850px) {
          margin: 16px 20px 0;
        }
      }
    }
  }
}
