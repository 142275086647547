@import '../../theme';

.Textarea {
  position: relative;
  margin: 0 0 15px;

  &:last-child {
    margin: 0;
  }

  &-Body {
    position: relative;
    margin-top: -10px;
    padding-top: 10px;
    overflow: hidden;
  }

  &-ValidationMessage {
    color: darken($errorTextColor, 10);
    white-space: nowrap;
  }

  &-Label {
    transform-origin: left;
    transition: all 0.2s;
    font-family: 'Roboto', sans-serif;
    position: absolute;
    font-size: 16px;
    top: 26px;
    left: 16px;
    color: darken($editBackgroundColor, 50);
    cursor: auto;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;

    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        top: 26px;
      }
    }

    &-filled {
      position: absolute;
      transition: all 0.1s;
      padding-left: 6px;
      padding-right: 6px;
      background: linear-gradient(
        0deg,
        rgba(242, 242, 242, 1) 0%,
        rgba(242, 242, 242, 1) 45%,
        rgba(255, 255, 255, 0) 45%,
        rgba(250, 248, 248, 0) 100%
      );
      transform: scale(0.8) translate(0, -33px);
      font-weight: bold;
      color: lighten($mainTextColor, 25);

      div {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  textarea {
    font-family: 'Roboto', sans-serif;
    outline: 0;
    background: $editBackgroundColor;
    width: 100%;
    border: 1px solid $editBorderColor;
    padding: 15px 15px 13px;
    box-sizing: border-box;
    font-size: 16px;
    resize: none;
    color: $mainTextColor;

    border-radius: 0;
    -webkit-appearance: none;

    &:hover,
    &:active,
    &:focus {
      border: 1px solid $mainActionColor;
    }
  }
}
