@import '../../theme';

.EditableInput {
  display: block;
  position: relative;
}

.EditableInput-View {
  display: block;
  position: relative;
}

.EditableInput-ViewContent {
  display: block;
  position: relative;
  flex: 1;
}

.EditableInput-NameLabel {
  font-weight: bold;
  margin-right: 5px;
  color: $mainTextColor;

  &:after {
    content: ':';
  }
}

.EditableInput-ValueLabel {
  cursor: pointer;
  border-bottom: 1px dashed $mainTextColor;
  position: relative;
  color: $mainTextColor;
  word-wrap: break-word;
}

.EditableInput-ValueIcon {
  display: inline-block;
  vertical-align: baseline;
  fill: $mainTextColor;
  margin-bottom: -2px;
}

.EditableInput-Edit {
  display: flex;
}

.EditableInput-ValueEdit {
  margin-right: 98px;
}

.Input .EditableInput-Actions {
  display: flex;
}

.Textarea .EditableInput-Actions {
  display: flex;
  flex-direction: column;
}

.Textarea .EditableInput-Actions .form-button {
  margin: 0;
}

.EditableInput-Edit.Textarea textarea {
  max-height: 98px;
  padding-right: 48px;
}

.EditableInput-Edit.Textarea .EditableInput-ShowViewButton {
  top: 1px;
  right: 1px;
}

.EditableInput-Edit.Textarea .EditableInput-ApplyButton {
  top: 50px;
  right: 1px;
}