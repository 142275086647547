$mainActionColor: #bb010b;
$mainActionTextColor: #faf8f8;
$secondActionColor: #dedede;
$secondActionTextColor: #bb010b;

$backgroundColor: #006f57;
$formBackgroundColor: #faf8f8;

$mainTextColor: #4c191b;
$emptyTextColor: #866769;
$errorTextColor: #e00018;
$secondTextColor: #f1ede2;

$editBackgroundColor: #f2f2f2;
$editBorderColor: #f2f2f2;

$alertBackgroundColor: #4c191b;
$alertTextColor: #f1ede2;
