@import '../../theme';

.form-button {
  margin: 0 0 15px;

  &:last-child {
    margin: 0;
  }

  button {
    @mixin button($background: $mainActionColor, $color: $mainActionTextColor) {
      background: $background;
      color: $color;
      margin: 0;

      &:hover,
      &:focus {
        background: darken($background, 5);
      }

      &:active {
        background: darken($background, 10);
      }

      &:active {
        box-shadow: 0 1px 2px 0 rgba(black, 0.46);
      }

      svg {
        fill: $color;
      }
    }

    @include button();

    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    outline: 0;
    width: 100%;
    border: 0;
    padding: 15px;
    font-size: 14px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
    position: relative;
    box-shadow: 0 2px 5px 0 rgba(black, 0.26);

    svg {
      margin-top: 1px;
      margin-bottom: -3px;
      width: 18px;
    }

    &.grey {
      @include button($secondActionColor, $secondActionTextColor);
    }
  }
}
