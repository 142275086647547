@import '../../theme';

.FieldView {
  display: block;
  position: relative;

  &-View {
    display: block;
    position: relative;
  }

  &-ViewContent {
    display: block;
    position: relative;
  }

  &-NameLabel {
    font-weight: bold;
    margin-right: 5px;
    color: $mainTextColor;

    &:after {
      content: ':';
    }
  }

  &-ValueLabel {
    position: relative;
    color: $mainTextColor;
    word-wrap: break-word;

    &-empty {
      color: $emptyTextColor;
    }
  }
}
