@import url(https://fonts.googleapis.com/css?family=Roboto:300);
@import 'theme';

body,
html,
#root,
.secret-santa {
  margin: 0;
  padding: 0;
  height: 100%;
  background: $backgroundColor;
  min-width: 320px;
}

#particles-js {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  overflow: hidden;
  opacity: 0.9;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}
