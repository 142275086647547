@import '../../theme';

.FormInput {
  position: relative;
  margin: 0 0 15px;

  &:last-child {
    margin: 0;
  }

  &-Body {
    position: relative;
    margin-top: -10px;
    padding-top: 10px;
    overflow: hidden;

    &-copied {
      margin-right: 49px;
    }

    &:after {
      z-index: 0;
      content: ' ';
      width: 10px;
      height: 47px;
      display: block;
      position: absolute;
      top: 11px;
      right: 1px;
      background: $editBackgroundColor;
    }

    &-togglePassword,
    &-sync {
      &:after {
        width: 50px;
      }
    }
  }

  &-ValidationMessage {
    color: darken($errorTextColor, 10);
    white-space: nowrap;
  }

  &-Label {
    transform-origin: left;
    transition: all 0.2s;
    font-family: 'Roboto', sans-serif;
    position: absolute;
    font-size: 16px;
    top: 26px;
    left: 16px;
    color: darken($editBackgroundColor, 50);
    cursor: auto;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;

    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        top: 26px;
      }
    }

    &-filled {
      position: absolute;
      transition: all 0.1s;
      padding-left: 6px;
      padding-right: 6px;
      background: linear-gradient(
        0deg,
        rgba(242, 242, 242, 1) 0%,
        rgba(242, 242, 242, 1) 45%,
        rgba(255, 255, 255, 0) 45%,
        rgba(250, 248, 248, 0) 100%
      );
      transform: scale(0.8) translate(0, -33px);
      font-weight: bold;
      color: lighten($mainTextColor, 25);

      div {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &-Input {
    font-family: 'Roboto', sans-serif;
    outline: 0;
    background: $editBackgroundColor;
    width: 100%;
    border: 1px solid $editBorderColor;
    padding: 15px 15px 13px;
    box-sizing: border-box;
    font-size: 16px;
    box-shadow: 0 2px 5px 0 rgba(black, 0.2);
    color: $mainTextColor;
    margin: 0;

    border-radius: 0;
    -webkit-appearance: none;

    &:hover,
    &:active,
    &:focus {
      border: 1px solid $mainActionColor;
    }

    &-invalid {
      border-color: darken($errorTextColor, 10);

      &:hover,
      &:active,
      &:focus {
        border: 1px solid darken($errorTextColor, 10);
      }
    }

    &-sync {
      padding-right: 45px;
    }

    &-togglePassword {
      padding-right: 50px;
    }
  }

  &-TogglePassword {
    z-index: 1;
    -webkit-tap-highlight-color: transparent;
    position: absolute;
    width: 25px;
    height: 25px;
    right: 13px;
    top: 23px;
    cursor: pointer;
    color: $mainActionColor;

    &-invalid {
      color: darken($errorTextColor, 10);
    }
  }

  &-SyncButton {
    z-index: 1;
    -webkit-tap-highlight-color: transparent;
    position: absolute;
    width: 25px;
    height: 25px;
    right: 13px;
    top: 23px;
    cursor: pointer;

    &-rotation {
      animation-name: rotation;
      animation-duration: 0.3s;
      animation-iteration-count: 1;
      animation-timing-function: cubic-bezier(0.52, 0.5, 0.25, 1);

      @keyframes rotation {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(-180deg);
        }
      }
    }
  }

  &-CopyButton {
    &-copied {
      width: 49px;
      position: absolute;
      top: 10px;
      right: 0;
    }
  }
}

.FormInput-TogglePassword ~ .FormInput-SyncButton {
  right: 45px;
}

.FormInput-Input-sync.FormInput-Input-togglePassword {
  padding-right: 80px;
}
