@import '../../theme';

/// List
.List {
  font-family: 'Roboto', sans-serif;
  margin-bottom: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.24);

  &:last-child {
    margin-bottom: 0;
  }
}

/// List-Title
.List-Title {
  background: $backgroundColor;
  color: $secondTextColor;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  font-size: 15px;
  padding: 15px 10px;
  text-transform: uppercase;
  font-weight: bold;
  overflow-wrap: break-word;
}

/// List-Item
.List-Item {
  position: relative;
  background: $backgroundColor;
  color: $secondTextColor;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  font-size: 15px;
  padding: 10px;
  border-top: 1px dashed rgba($formBackgroundColor, 0.3);
  overflow-wrap: break-word;
  word-break: break-word;
  display: flex;
  justify-content: space-between;

  &:first-child {
    border-top: none;
  }

  &:hover {
    background: darken($backgroundColor, 5);
    transition: 0.1s all ease;
    padding-left: 20px;
  }
}

.List-Item-selected {
  background: darken($backgroundColor, 7);
  &:hover {
    background: darken($backgroundColor, 9);
  }
}

.List-readonly {
  .List-Item {
    padding-left: 10px;
    background: $backgroundColor;
  }

  .List-Item-selected {
    background: darken($backgroundColor, 5);
  }
}

.List-fixed {
  max-height: 300px;
  overflow: auto;
}

/// List-ItemAction
.List-ItemAction {
  cursor: pointer;
  display: none;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.78);
  white-space: nowrap;
  margin-left: 10px;
}

.List-Item:hover {
  .List-ItemAction {
    display: inline-block;
  }
}

.List-Item-selected {
  .List-ItemAction {
    display: inline-block;
  }
}

.List-readonly .List-Item {
  .List-ItemAction {
    display: none;
  }
}
